<template>
  <div>
    <!-- <v-row>
      <h1 class="ds-page-title">Your personal equity manager</h1>
    </v-row>
    <v-row>
      <h2 class="ds-page-subtitle">
        A nice benefit line to be added here that gives the quick overview of
        the program, followed by 2-3 key pointers.
      </h2>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col><v-icon class="ds-icon">mdi-lightbulb</v-icon></v-col>
        </v-row>
        <v-row>
          <v-col class="ds-card-title">What is fear management?</v-col>
        </v-row>
        <v-row>
          <v-col class="ds-card-text"
            >Perquity uses a study ‘Staircase’ method of buying and selling in
            your own lots of any scrip on the basis of percentage of profit
            predecided by every user of their own, on the basis of less risky
            scrips out of your own holdings, which are bullish in the
            fluctuating markets. This eventually decreases the average price of
            the best holdings out of your whole lot.</v-col
          >
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col><v-icon class="ds-icon">mdi-lightbulb</v-icon></v-col>
        </v-row>
        <v-row>
          <v-col class="ds-card-title">What is greed management?</v-col>
        </v-row>
        <v-row>
          <v-col class="ds-card-text"
            >Alarming and satisfying your ‘Greed’ to book profits time to time
            in small lots from your scrips with their upward movements. Here in
            this program every user is the master in deciding Percentage of
            profit flexibly of their own, depending upon frequency of your
            trading on medium to long term basis.</v-col
          >
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col><v-icon class="ds-icon">mdi-lightbulb</v-icon></v-col>
        </v-row>
        <v-row>
          <v-col class="ds-card-title">What is fear management?</v-col>
        </v-row>
        <v-row>
          <v-col class="ds-card-text"
            >Perquity uses a study ‘Staircase’ method of buying and selling in
            your own lots of any scrip on the basis of percentage of profit
            predecided by every user of their own, on the basis of less risky
            scrips out of your own holdings, which are bullish in the
            fluctuating markets. This eventually decreases the average price of
            the best holdings out of your whole lot.</v-col
          >
        </v-row>
      </v-col>
    </v-row> -->
        <div class="faq-header">
			<h1> Below are a few questions we know you’ll have before you jump into using PERQUITY to its full potential. </h1>
			<!-- <p>A nice benefit line to be added here that gives the quick overview of the program, followed by 2-3 key pointers.</p> -->
		</div>
		
		<div class="accordion">
			<div class="accordion__header is-active">
				<h2>1. Can I make equity investments through PERQUITY?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body is-active">
				<p>No. This is not a DEMAT account or Trading platform. It is an account/inventory manager of your equity investments portfolio, or as we like to think of it, a ‘Smart Portfolio Management Program’. For trading  you can use any  other trading platform available in the country. However, you will need to update your PERQUITY portfolio based on the transactions you make on these other platforms to ensure that you get the best Buy / Sell Advice.</p>
			</div>
			<div class="accordion__header">
				<h2>2. What is an SIP in equity investment?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>Systematic Investment Plan or SIP in equity means that whenever you want to invest in a new share for your portfolio, you do not put the entire targeted amount in one go. Instead you invest that amount in two to four installments based on the shares performance i.e. you only invest subsequent installments if the share moves in the price increment direction as per your plan.</p>
<p>The best results for your portfolio can be achieved if you plan an approximate amount for one lot, instead of putting a chunk amount in one particular share. This planned and systematic investment allows you to have capital available for the best performing shares of your portfolio.</p>
				<!-- <p><strong>Free worldwide shipping</strong></p>
				<p>Your order will be dispatched the next day.</p> -->
			</div>
			<div class="accordion__header">
				<h2>3. What is Profit Ratio, and its significance on the PERQUITY platform?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>This is a very important tool which is significant if your equity investments are in small lots (SIP investments) as elaborated in the second question in this section. </p>
				<p>Basically, you should have small targets (in Perquity we call them the stairs to greater heights) which allow you to reach the big target (or your final goal). As the share market is very strange, you can never predict that your final goal will be achieved and if it is achieved, how long a period it will take to do so. </p>
				<p>Due to certain conditions, it may not be achieved, like whenever there is a market crash. Or perhaps you were too busy when it reached there. So the Profit Ratio is this small staircase of your profit target.</p>
				<p>This means for every purchase you should expect a small percentage of rise in price, because that is the goal you have when you made the purchase.</p>   
				<p>Same thing happens if you sell any lot in your journey and find that you want to repurchase it if the share is bouncing back.</p>     
			</div>
			<div class="accordion__header">
				<h2>4. How should I decide on the right Percentage for my profit ratio?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>This is our suggested way of deciding on the Profit Ratio:</p>
				<p>- If you are able and willing to check and transact almost every day on the share market, then our suggested Profit Ratio is between 5%  to 7%.</p>
				<p>- If you cannot afford to dedicate your time daily for share transactions but you can give at least some trading time once or twice in a week then our suggested rate of greed is 8% to 10%.</p>
				<p>- If you can afford to dedicate your time once or twice in a fortnight then our suggested rate of greed is 10% to 15%.</p>
				<p>- If you can only make time once in a month then we suggest any rate between 15 to 20%.</p>
				<p>You can change this percentage at any time but do not do so frequently.</p>
			</div>
			<div class="accordion__header">
				<h2>5. What is the meaning of “FAIR PRICE” of a share?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>It is the average of the two prices of a particular share, meaning, (Year’s High + Year’s Low)/2. This price is the equilibrium between bulls and bears in a particular year.</p>
			</div>
			<div class="accordion__header">
				<h2>6. What is the meaning of “Consolidated Rate” of a share ?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>This is the net average price of a share in your portfolio after several transactions of buying and selling in that particular share. To determine it go to the portfolio in your DEMAT account and simply calculate as follows:</p>
				<p>Consolidated Rate = (A - B) / D or (A + C) / D</p>
				<p>Where:</p>
				<p>A is the value at cost</p>
				<p>B is the realised profit</p>
				<p>C is the realised loss</p>
				<p>D is the Net Quantity of shares remaining with you</p>
			</div>
			<div class="accordion__header">
				<h2>7. What is the meaning of “ROI” in Perquity ?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>Return on Investment (RoI) = (A - B) *100 / B</p>
				<p> Where:</p>
				<p>A is the Total Market value of your portfolio.</p>
<p>B is the Composite Purchase value of your portfolio.</p>
<p>The RoI can also be referred to as the profit percentage, and we call it “PORTFOLIO ROI” displayed on the right top corner of your “Portfolio Overview” page.</p>
			</div>
			<div class="accordion__header">
				<h2>8. What is the meaning/significance of five categories in PERQUITY ?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>The profitability of every share in your portfolio is different depending upon your Purchase Price and the Market price on any particular day. The “Average profit percentage”/ “Portfolio ROI” of your total portfolio, is divided into 5 categories, as follows:</p>
				<p><strong>PERGROWTH Category</strong> =” Individual profit percentage”/ “Individual ROI” of a particular share is <strong>MORE</strong> than your “Average profit percentage”/  “Portfolio ROI”.</p>
				<p><strong>PERCHANCE Category</strong> = Individual profit percentage”/ “Individual ROI” of a particular share is <strong>LESS</strong> than your “Average profit percentage”/ “Portfolio ROI” but is higher than 10%</p>
				<p><strong>PERNEED Category</strong> = Individual profit percentage”/ “Individual ROI” of a particular share is  <strong>LESS</strong> than 10% however more than 0% (Positive on a particular day) irrespective of “Portfolio ROI”.</p>
				<p><strong>PERISHABLE Category</strong> = All the individual shares in which you are bearing loss, Where Your Purchase rate is more than Market Rate on a particular day.</p>
				<p><strong>PERFECT Category</strong> = Super profitable shares. Either due to very heavy increments in the market price or because a large quantity is sold at very good profit. The “Profit percentage” shares that will cross the 1000% mark fall in this category.</p>
			</div>
			<div class="accordion__header">
				<h2>9. What is the meaning of “Average category Investment” ?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>
					There are 5 categories in the PERQUITY management system (Perfect to Perishable) so altogether there are 5 values in all 5 categories.</p>
				<p>
					Average Category investment is calculated as X/Y 
				</p>
				<p>
					Where X is the Total of all consolidated values of all scrips in a particular category And Y is the Total number of different scrips in that category.
				</p>
			</div>
			<div class="accordion__header">
				<h2>10. What is the minimum amount one can start investing in Equities/ Shares ?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>The cost of a DEMAT account maintenance varies from Rs.500 to Rs.2,500 per year, therefore we believe that at the end of a year you should have invested more than Rs.10,000 (which if calculated via the SIP method works out to just Rs.1,000 per month).</p>
			</div>
			<div class="accordion__header">
				<h2>11. What rate of Purchase/ Sale of a particular transaction should be filled in PERQUITY program  ?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>There are two possible rates of purchase/sale, namely the market traded price or traded price after consideration of brokerage of your DEMAT account. So it is very important that one has to choose either of the two uniformly using the PERQUITY program. (The best results can be achieved if use only rates after brokerage for all your shares).</p>
			</div>
			<div class="accordion__header">
				<h2>12. What are “LOWER BAND” and “UPPER BAND”  in “PURCHASE LIST”,  their meaning and significance ?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>One simple inference is that you should Buy the next lot of a particular share only when it goes below “ LOWER BAND” or higher than the “UPPER BAND”, and not in between. These bands are determined as per the “Profit Ratio” selected by you. </p>
			</div>
			<div class="accordion__header">
				<h2>13. What is the difference between the highlighted band (Purple Highlight) and the simple band out of these two bands in Purchase List?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>The highlighted band (Purple Highlight) is the positive decision suggested for you, based on your last treatment of Buying/Selling that particular share. However, the other band is the alternative action which you can take which is opposite to the suggestion of the program,  when you find that the direction of movement of the share price is in a reverse direction. This is called “Speculative” buying.</p>
			</div>
			<div class="accordion__header">
				<h2>14. What is Speculative buying?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>It is when the Program suggestion is to buy one lot of a share if the price goes below “LOWER BAND”. This happens whenever you have sold any lot at a higher price before. </p>
				<p>However, practically if we find that because of a Bullish run of that share it does not appear to go down. In those circumstances you can choose to REBUY that share at more than “UPPER BAND”. This action is called Speculative Buying, and is harmless for the health of your portfolio in the long run. </p>
			</div>
			<div class="accordion__header">
				<h2>15. Why are there two yardsticks of Selling a share 	?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>These are the “TARGET” and “YEAR’S HIGH”, out of which if the “TARGET” is of a higher value, then you should wait for the “YEAR’S HIGH” to achieve the “TARGET” in order to sell a lot of your share.</p>
				<p>And if the “YEAR’S HIGH” is higher, then you have the choice to wait or to sell even if your “TARGET” is achieved, depending upon your need of funds.</p>
			</div>
			<div class="accordion__header">
				<h2>16. What are the different Sectors for creating our diversified portfolio ?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>This needs to be looked at only as a recommended list based on our experience so far. Broadly we have divided a total of 40 sectors which cover almost all the companies of our country. To make it simple for you,  we have graded them in 3 categories (which are modified on a quarterly basis based on the input of our research team):</p>
				<p>(a)   <strong>MUST</strong> for a Portfolio : AUTOMOBILE MAIN, AUTOMOBILE AUTO ANCILLARIES, CEMENT, CHEMICALS, CONSUMER GOODS (FMCG), FINANCIAL SERVICES (BANKING), INDUSTRIAL MFG. (ELECTRICAL & ELECTRONICS), INDUSTRIAL MFG. (ENGINEERING GOODS), INFORMATION TECHNOLOGIES, METALS & MINERALS, OIL & GASSES, PHARMA, POWER SECTOR, DIVERSIFIED (LARGE CAPS).</p>
				<p>(b)   <strong>GOOD</strong> for a Portfolio:  AGRICULTURAL PRODUCTS, AUTOMOBILE (TYRES/RUBBER), CONSTRUCTION (REALITY & HOUSING), CONSTRUCTION (INFRASTRUCTURE), CONSUMER GOODS (SUGAR), CONSUMER GOODS (FOODS & SERVICES), CONSUMER GOODS (LEATHER PRODUCTS), CONSUMER GOODS (LIQUER), CONSUMER GOODS (PAINTS), CONSUMER GOODS (RETAIL), CONSUMER GOODS (MISCELLANEOUS), FERTILIZERS & PESTICIDES, FINANCIAL SERVICES (NBFC & OTHERS), HEALTH CARE SERVICES, TELECOMMUNICATION, TEXTILE & TEXTILE MACHINERY, HOLDING & OTHERS.</p>
				<p>(c)	<strong>FAIR</strong> for a Portfolio : AVIATION, CONSUMER SERVICES (HOTELS), EDUCATION, FINANCIAL SERVICES (INSURANCE), MEDIA & ENTERTAINMENT, PAPER & PACKAGING, SHIPPING & PORTS, TEA & COFFEE, TRANSPORT & RAILWAYS.</p>
			</div>	
			<div class="accordion__header">
				<h2>17. What is the “X/2X/3X Distribution” method of spreading your investment ?</h2>
				<span class="accordion__toggle"></span>
			</div>
			<div class="accordion__body">
				<p>When looking at distribution, the X value simply means the SIP amount that you are willing to spend on a single share. For example if you are looking to invest in 10 shares with an overall amount of Rs. 1,00,000 then your SIP per share distributed evenly will be approx. Rs. 10,000. This amount can be spent on shares in any of the 5 categories without significant risk.(Note: The figure of Rs, 10,000 used here onwards only as an example to explain the meaning of X).</p>
				<p>2X distribution means that you are looking to double your investment in a single share. Using the example above, if Rs. 10,000 is the amount you would normally invest on a single share out of your Rs. 1,00,000 then we would recommend using Rs. 10,000 x 2 or Rs. 20,000 for a share in the Perfect and Pergrowth categories. For Perchance, Perneed and Perishable we would recommend using the remaining amount divided equally into Rs. 10,000 SIP for investment into any share. The reason for this is that as per your set Profit Ratio, the shares in the first two categories are less risky and are more likely to provide you with a return.</p>
				<p>Similarly a 3X distribution is where you invest 3X times  for a single share in “PERFECT” category. 2X times for single share in “PERGROWTH” category and X amount in the of the three categories. </p>
			</div>
		</div>
		<div class="faq-header" style="margin-bottom:20px;">
			<h2>We hope to have answered all your questions, however keep in mind that these are merely suggestions and in the end, you can find your own innovative investment strategy using the “PERQUITY” platform. The power is in your hands, we’re just here to help you manage it.</h2>
		</div>
  </div>
</template>

<script>
export default {
  name: 'FAQs',
  mounted() {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"
    );
    recaptchaScript.setAttribute(
      "src",
      "/js/script.js"
    );
    document.head.appendChild(recaptchaScript);
  },
}
</script>

<style lang="scss" scoped>
@import '~@/scss/variables.scss';
.ds-icon {
  border-radius: 2rem;
  background-color: $colors-brand-primary;
  color: $colors-brand-neutral_light-7;
  width: 2.5rem;
  height: 2.5rem;
}
.ds-page-title {
  @include display_large_medium;
  margin-top: 4.375rem;
  line-height: 5rem;
}
.ds-page-subtitle {
  @include heading_2_regular;
  margin-top: 1.875rem;
  color: $colors-brand-primary;
  margin-bottom: 3.125rem;
}
.ds-card-title {
  @include paragraph_1_bold;
}
.ds-card-text {
  @include paragraph_2_medium;
}
</style>
